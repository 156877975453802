<script setup>
const { isSafari } = useDevice();
</script>

<template>
  <svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21 12C21 16.9706 16.9706 21 12 21C9.69494 21 7.59227 20.1334 6 18.7083L3 16M3 12C3 7.02944 7.02944 3 12 3C14.3051 3 16.4077 3.86656 18 5.29168L21 8M3 21V16M3 16H8M21 3V8M21 8H16"
      stroke="url(#gradient-refresh)"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <defs v-if="!isSafari">
      <linearGradient id="gradient-refresh" x1="22.4728" y1="11.874" x2="2" y2="11.874" gradientUnits="userSpaceOnUse">
        <stop stop-color="#F0899E" />
        <stop offset="0.244792" stop-color="#9617D2" />
        <stop offset="0.510417" stop-color="#4E0FFF" />
        <stop offset="0.75" stop-color="#2C60FD" />
        <stop offset="1" stop-color="#30F0F6" />
      </linearGradient>
    </defs>
    <linearGradient
      v-else
      id="gradient-refresh"
      x1="22.4728"
      y1="11.874"
      x2="2"
      y2="11.874"
      gradientUnits="userSpaceOnUse"
    >
      <stop stop-color="#F0899E" />
      <stop offset="0.244792" stop-color="#9617D2" />
      <stop offset="0.510417" stop-color="#4E0FFF" />
      <stop offset="0.75" stop-color="#2C60FD" />
      <stop offset="1" stop-color="#30F0F6" />
    </linearGradient>
  </svg>
</template>
